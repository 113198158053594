import Bugsnag from '@bugsnag/js'
import { mergeQueryParameters } from '@/utils/UriBuilder'
import { TypedTrackFn } from '@/utils/analytics'

export type TheatricalErrorContext =
  | 'InitializeReservation'
  | 'SetShowtimes'
  | 'SetSeatQuantity'
  | 'SetSeatIds'
  | 'CommitReservation'
  | 'CommitPreorderReservation'
  | 'GetReservation'
  | 'RenewReservation'
  | 'SetTicketPif'
  | 'UpdateReservationEmail'
  | 'CancelReservation'
  | 'InitializeReservationChange'
  | 'GuildReservationSubscription'
  | 'AddPreOrderTicketOption'

export type TheatricalReservationEvent =
  | 'Initialized Ticket Reservation'
  | 'Tickets Showtime Selected'
  | 'Seat Pressed'
  | 'Reserved Seats'
  | 'Set Seat Quantities'
  | 'Committed Ticket Reservation'
  | 'Reservation Known Errors'
  | 'Reservation Unexpected Error'
  | 'Theatrical User Contacted Support'

interface TrackUnexpectedOptions {
  context: TheatricalErrorContext
  error: { message: string }
  track: TypedTrackFn
  uuid: string
  reservationId: string
  extraMetadata?: Record<string, unknown>
}

interface BugSnagKnownProps {
  context: TheatricalErrorContext
  errors: { reference: string; description: string }[]
  uuid: string
}
export const bugSnagKnownReservationErrors = ({ context, errors, uuid }: BugSnagKnownProps) => {
  errors.forEach((error) => {
    notifyBugsung('Reservation Unexpected Error', { context, message: error.description, uuid: uuid })
  })
}
export const trackUnexpectedReservationError = ({
  context,
  track,
  error,
  reservationId,
  extraMetadata = {},
}: TrackUnexpectedOptions) => {
  if (error?.message) {
    track('Reservation Unexpected Error', { errorMessage: error.message, error, reservationId, ...extraMetadata })
    return notifyBugsung('Reservation Unexpected Error', { context, message: error.message, ...extraMetadata })
  }
  track('Reservation Unexpected Error', { error, reservationId })
  notifyBugsung('Reservation Unexpected Error', {
    context,
    message: 'UnknownReservationError',
    error,
    ...extraMetadata,
  })
}

export type KnownReservationErrors = { reference: string; description: string }[]
interface TrackKnownOptions {
  context: TheatricalErrorContext
  errors: KnownReservationErrors
  track: TypedTrackFn
  reservationId: string
}

export const trackKnownReservationError = ({ context, track, errors, reservationId }: TrackKnownOptions) => {
  track('Reservation Known Errors', { errors, context, reservationId })
}

export interface TrackUserContactOptions {
  context: string
  uuid: string
  track: TypedTrackFn
  reservationId?: string
}

export const trackUserContactSupport = ({ context, uuid, track, reservationId }: TrackUserContactOptions) => {
  notifyBugsung('User Contacted Support', { context, uuid })
  track('User Contacted Support', { uuid, context, reservationId })
}

interface TheatricalBugsnagMetadata {
  context: string
  error?: { message: string }
  [p: string]: { message: string } | string | undefined
}

export const notifyBugsung = (error: string, metadata: TheatricalBugsnagMetadata) => {
  Bugsnag.notify(new Error(`Critical Theatrical Failure: ${error}`), (errorEvent) => {
    errorEvent.addMetadata('tracing', metadata)
  })
}

interface ZendeskLinkProps {
  reservationId: string
  email?: string
  errorId?: string
}
export const createZendeskLink = ({ reservationId, email, errorId }: ZendeskLinkProps) => {
  const baseParams = {
    ticket_form_id: '6742195490455',
    tf_anonymous_requester_email: email ?? '',
    tf_19423688123671: reservationId,
    tf_6742404389015: 'computer_web_page_field',
  }
  if (errorId) {
    return mergeQueryParameters('https://support.angel.com/hc/requests/new', {
      ...baseParams,
      tf_19579737983127: errorId,
    })
  }
  return mergeQueryParameters('https://support.angel.com/hc/requests/new', {
    ...baseParams,
  })
}
