import React, { FC, useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { Button } from '@/atoms/Button'
import { FlexColumn, FlexRow, ResponsiveFlexRow } from '@/atoms/FlexContainers'
import { CouponIcon } from '@/atoms/Icons/CouponIcon'
import { CaptionLG, CaptionSM, CaptionXS } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { ReservationObject } from '@/types/codegen-federation'
import { writeToLocalStorage } from '@/utils/local-storage'
import { useTranslate } from '@/utils/translate/translate-client'

interface Props {
  step: number
  priorReservation: ReservationObject
  description: string
}
const ChangeReservationBanner: FC<Props> = ({ step, priorReservation, description }) => {
  const { push, back } = useRouter()
  const { t } = useTranslate('tickets')
  const { theatricalSlug, id } = priorReservation

  const steps = useMemo(() => {
    return [
      {
        step: 1,
        label: t('showtime', 'Showtime'),
        isActive: step >= 1,
        path: paths.tickets.checkout.reservationChange(theatricalSlug, priorReservation?.id),
      },
      { step: 2, label: t('seats', 'Seats'), isActive: step >= 2 },
      { step: 3, label: t('checkout', 'Checkout'), isActive: step >= 3 },
    ]
  }, [priorReservation?.id, step, t, theatricalSlug])

  const priorReservationPath = `${paths.tickets.checkout.complete(theatricalSlug, id)}?order=edit`

  const handleCancel = useCallback(() => {
    writeToLocalStorage('reservation_change_canceled', true)
    push(priorReservationPath)
  }, [priorReservationPath, push])

  return (
    <ResponsiveFlexRow
      className={classNames('gap-4 px-6 py-3 text-white md:justify-between relative z-30 bg-core-gray-800')}
    >
      <FlexRow className="justify-between gap-4">
        <FlexColumn className="gap-0.5">
          <FlexRow className="gap-1">
            <CouponIcon color="warm-03" />
            <CaptionLG color="warm-03" weight="bold">
              {t('modifyTickets', 'Modify Reservation')}
            </CaptionLG>
          </FlexRow>
          <CaptionSM weight="normal" color="white">
            {description}
          </CaptionSM>
        </FlexColumn>
        <Button className="text-xs md:hidden" onClick={() => push(priorReservationPath)} variant="white">
          Cancel
        </Button>
      </FlexRow>

      <FlexRow className="relative w-full justify-between px-4 pb-4 text-center md:max-w-sm">
        {steps.map((stepValue) => (
          <ChangeReservationStep
            onClick={() => {
              if (stepValue?.step + 1 === step) return back()
              if (stepValue?.path) push(stepValue?.path)
            }}
            key={stepValue.step}
            step={stepValue.step}
            disabled={stepValue.step >= step}
            label={stepValue.label}
            isActive={stepValue.isActive}
          />
        ))}
      </FlexRow>

      <Button className="hidden text-xs md:block" onClick={handleCancel} variant="white">
        Cancel
      </Button>
    </ResponsiveFlexRow>
  )
}

interface ChangeReservationStepProps {
  step: number
  label: string
  isActive: boolean
  disabled?: boolean
  onClick?: () => void
}
const ChangeReservationStep: FC<ChangeReservationStepProps> = ({
  step,
  label,
  isActive,
  onClick,
  disabled = false,
}) => {
  return (
    <>
      {step !== 1 && <div className="mx-1 h-[2px] w-full bg-white" />}
      <FlexColumn className="relative z-10 items-center gap-2">
        <div
          onClick={() => {
            if (onClick && !disabled) onClick()
          }}
          className={classNames(
            'rounded-full w-6 h-6 flex items-center justify-center border-[1.5px] border-white',
            isActive ? 'bg-warm-03 text-white border-warm-03' : 'text-white',
            !disabled && 'cursor-pointer',
          )}
        >
          <CaptionSM>{step}</CaptionSM>
        </div>
        <CaptionXS className="absolute left-1/2 top-full mt-1 -translate-x-1/2" color="white">
          {label}
        </CaptionXS>
      </FlexColumn>
    </>
  )
}
export default ChangeReservationBanner
