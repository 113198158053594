import { ParsedUrlQuery } from 'querystring'
import { ReservationVenueAddress, TheatricalShowtime } from '@/types/codegen-federation'
import { CustomReservationSeat } from '@/views/TicketCheckoutViews/SeatMapView/components/SeatMapPage'
import { TicketsAnalyticsPayload } from '@/views/TicketCheckoutViews/ShowtimesView/components/TicketsContext'

export function getHoursMinutes(minutes: number, locale: string) {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  const formattedHours = new Intl.NumberFormat(locale).format(hours)
  const formattedMinutes = new Intl.NumberFormat(locale).format(remainingMinutes)
  if (hours) {
    return `${formattedHours}H ${formattedMinutes}M`
  }

  return `${formattedMinutes}M`
}

export const getDiscountCodes = ({ query }: { query: ParsedUrlQuery }) => {
  const discountsQuery = query?.discounts
  if (discountsQuery && typeof discountsQuery === 'string') return discountsQuery.split(',')
  return []
}

export const getCodes = ({ query }: { query: ParsedUrlQuery }) => {
  const codesQuery = query?.codes
  if (codesQuery && typeof codesQuery === 'string') return codesQuery.split(',')
  return []
}

export const getFlags = ({ query }: { query: ParsedUrlQuery }) => {
  const flagsQuery = query?.flags

  if (flagsQuery && typeof flagsQuery === 'string') return flagsQuery.split(',')
  return []
}
export const getSelectedSeatRate = (
  seatId: string,
  seats: Map<string, CustomReservationSeat>,
  guildTicketAvailable: boolean,
) => {
  let selectedRate = seats.get(seatId)?.selectedRate

  // hijack the discountPrice if guild tickets are available, since they're not currently accounted for in the data
  if (guildTicketAvailable && selectedRate?.discountedPrice) {
    selectedRate = {
      ...selectedRate,
      discountedPrice: {
        ...selectedRate.discountedPrice,
        currencyAmount: 0,
      },
    }
  }
  return selectedRate
}
export const formatAddress = (address: ReservationVenueAddress | undefined) => {
  if (!address) return ''
  const { line, countryCode, state, city } = address
  if (countryCode === 'US') return `${line}, ${city}, ${state}`
  return `${line}, ${city}, ${countryCode}`
}

export const isStatusValid = (status: string) => {
  const invalidStatuses = new Set(['COMPLETE', 'CANCELLED'])
  return !invalidStatuses.has(status)
}

export const isEmailValid = (email: string) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  return emailRegex.test(email)
}

export const getLanguageName = (locale: string): string => {
  try {
    const displayNames = new Intl.DisplayNames([locale], { type: 'language' })
    return displayNames.of(locale) as string
  } catch (err) {
    return locale
  }
}

export const getLanguageNameUsingUserLocale = (locale: string, lang: string): string => {
  try {
    const displayNames = new Intl.DisplayNames([locale], { type: 'language' })
    return displayNames.of(lang) as string
  } catch (err) {
    return locale
  }
}

export const generateTrackingPayload = (
  showTime: TheatricalShowtime,
  analyticsPayload: TicketsAnalyticsPayload,
  reservationId?: string | undefined,
) => {
  return {
    ...analyticsPayload,
    reservationId: reservationId ?? null,
    startTime: showTime.utcStartTime,
    showtimeId: showTime?.id,
  }
}
