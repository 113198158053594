import { useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import { useToast } from '@/molecules/Toast'
import { useSafeTrack } from '@/utils/analytics'
import { writeToLocalStorage } from '@/utils/local-storage'
import {
  TheatricalErrorContext,
  trackUnexpectedReservationError,
} from '@/views/TicketCheckoutViews/utils/errorTrackingUtils'

interface Props {
  reservationId: string
}
/* eslint-disable @typescript-eslint/no-explicit-any */
interface HandleUnexpectedErrorProps {
  context: TheatricalErrorContext
  customToastMessage?: string
  error: { message: string } | any
  extraMetadata?: Record<string, unknown>
}
const useHandleUnexpectedError = ({ reservationId }: Props) => {
  const track = useSafeTrack()
  const { showToast } = useToast()

  const handleUnexpectedError = useCallback(
    ({ context, customToastMessage, error, extraMetadata }: HandleUnexpectedErrorProps) => {
      const errorId = uuid()
      writeToLocalStorage('ATOM_ERROR_ID', errorId)

      trackUnexpectedReservationError({
        context,
        error,
        track,
        reservationId,
        uuid: errorId,
        extraMetadata,
      })

      if (customToastMessage) return showToast(customToastMessage)
      if (error?.message) return showToast(error.message)
    },
    [reservationId, showToast, track],
  )

  return { handleUnexpectedError }
}

export default useHandleUnexpectedError
