// DatadogRumFull.tsx
import Script from 'next/script'

const DatadogRumFull = () => (
  <Script
    id="datadog"
    strategy="afterInteractive" // Loads the script after the page is interactive
    dangerouslySetInnerHTML={{
      __html: `
        (function(h,o,u,n,d) {
          h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
          d=o.createElement(u);d.async=1;d.src=n;
          n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n);
        })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM');
        window.DD_RUM.onReady(function() {
          window.DD_RUM.init({
            clientToken: '${process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || ''}',
            applicationId: '${process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || ''}',
            site: 'datadoghq.com',
            sessionSampleRate: 1,
            sessionReplaySampleRate: 0,
            trackResources: true,
            trackLongTasks: true,
            trackUserInteractions: true,
            defaultPrivacyLevel: 'allow',
          });
        });
      `,
    }}
  />
)

export default DatadogRumFull
