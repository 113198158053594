import React, { useCallback, useMemo } from 'react'
import { ApolloProvider } from '@apollo/client'
import classNames from 'classnames'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { Analytics } from '@/layout/Analytics'
import { ErrorBoundary, ErrorFallback } from '@/layout/ErrorBoundary'
import { DefaultSeo } from '@/layout/Seo'
import { TicketNavBar } from '@/layouts/TicketLayout/components/TicketNavBar'
import { TicketNavVariantTypes } from '@/layouts/TicketLayout/components/TicketNavBar/TicketNav'
import { LanguagesProvider } from '@/molecules/LanguageSelector'
import { ToastProvider } from '@/molecules/Toast/ToastProvider'
import { getWebClient } from '@/services/ApolloClient'
import { TheatricalReleaseRegion } from '@/types/codegen-federation'
import { useLocale } from '@/utils/LocaleUtil'
import DatadogRumFull from '@/utils/datadog/DatadogRumFull'

interface DefaultLayoutProps {
  children: React.ReactNode
  title?: string
  regions?: TheatricalReleaseRegion[]
  shouldRemoveNavOnMobile?: boolean
  isDarkMode?: boolean
  className?: string
  navClassName?: string
  shouldShowBackNavigation?: boolean
  shouldShowCloseNavigation?: boolean
  onNavigationClose?: () => void
  shouldShowLanguageModal?: boolean
}

export const TicketsLayout: React.FC<DefaultLayoutProps> = ({
  children,
  regions,
  shouldRemoveNavOnMobile = false,
  className,
  title,
  isDarkMode = true,
  navClassName,
  shouldShowBackNavigation = false,
  shouldShowCloseNavigation = false,
  shouldShowLanguageModal = false,
  onNavigationClose,
}) => {
  const { locale } = useLocale()
  const client = getWebClient({ locale })
  const { query, isReady, back, asPath, push } = useRouter()
  const shouldShowBack = shouldShowBackNavigation || (isReady && query?.navigation === 'back')

  const navigationVariant = useMemo<TicketNavVariantTypes>(() => {
    if (shouldShowBack) return 'BACK'
    if (shouldShowCloseNavigation) return 'CLOSE'
    return 'NONE'
  }, [shouldShowBack, shouldShowCloseNavigation])

  const goBack = useCallback(() => {
    if (asPath?.includes('seats')) {
      if (query?.reservationId) return back()
      return push(asPath?.split('showtime')[0])
    }
    back()
  }, [asPath, back, push, query?.reservationId])

  const handleNavigationIconClick = useCallback(() => {
    if (shouldShowBack) return goBack()
    if (shouldShowCloseNavigation && onNavigationClose) return onNavigationClose()
    return null
  }, [goBack, onNavigationClose, shouldShowBack, shouldShowCloseNavigation])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Head>
        <link rel="dns-prefetch" href="//app.omniconvert.com" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
        window._mktz=window._mktz||[];
        `,
          }}
        />
      </Head>
      <Script src="//cdn.omniconvert.com/js/v33131c.js" />
      <DatadogRumFull />
      <DefaultSeo />
      <LanguagesProvider>
        <Analytics>
          <ApolloProvider client={client}>
            <ToastProvider>
              <ThemeContextProvider isDarkMode={isDarkMode}>
                <div className={classNames(isDarkMode ? 'bg-core-gray-950' : 'bg-core-gray-100', className)}>
                  <TicketNavBar
                    shouldShowLanguageModal={shouldShowLanguageModal}
                    regions={regions}
                    shouldHideOnMobile={shouldRemoveNavOnMobile}
                    navClassName={navClassName}
                    title={title}
                    navigationVariant={navigationVariant}
                    onNavigationIconClick={handleNavigationIconClick}
                  />
                  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
                </div>
              </ThemeContextProvider>
            </ToastProvider>
          </ApolloProvider>
        </Analytics>
      </LanguagesProvider>
    </ErrorBoundary>
  )
}
